<template>
  <div>
    <Spinner v-if="users.length <= 0 || isLoading"/>
    <div v-else>
      <b-row align-v="center" class="mt-5">
        <b-col md="10" offset="1">
          <b-row>
            <b-col>
              <h5>Gestion des utilisateurs Statshop</h5>
              <b-row class="my-3">
                <b-col md="3">
                  <b-input-group size="sm">
                    <b-form-input
                        id="filter-input"
                        v-model="filterStatshop"
                        type="search"
                        placeholder="Rechercher un utilisateur"
                    ></b-form-input>
                  </b-input-group>
                </b-col>
              </b-row>
              <b-table
                  striped hover
                  id="users-statshop-table"
                  empty-text="Aucun utilisateur n'a été récupéré"
                  :items="usersStatshop"
                  :fields="fieldsUsersStatshop"
                  :current-page="currentPageStatshop"
                  :per-page="perPageStatshop"
                  :filter="filterStatshop"
                  :filter-included-fields="filterOnStatshop"
                  stacked="sm"
                  show-empty
                  small
                  @filtered="onFilteredStatshop"
                  @row-clicked="showModalUserStatshop"
              >
              </b-table>
              <b-row>
                <b-col md="3">
                  <b-pagination
                      v-model="currentPageStatshop"
                      :total-rows="totalRowsStatshop"
                      :per-page="perPageStatshop"
                      size="sm"
                  ></b-pagination>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row class="mt-5" align-v="center">
        <b-col md="10" offset="1">
          <h5>Gestion des utilisateurs de la WebApp</h5>
          <b-row class="my-3">
            <b-col md="3">
              <b-input-group>
                <b-form-input
                    id="filter-input"
                    v-model="filter"
                    type="search"
                    placeholder="Rechercher un utilisateur"
                ></b-form-input>
              </b-input-group>
            </b-col>
            <b-col md="2">
              <b-button @click="showModalCreateUser" type="button" variant="primary" block>Ajouter un utilisateur
              </b-button>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-table
                  striped hover
                  id="users-table"
                  empty-text="Aucun utilisateur n'a été récupéré"
                  :items="users"
                  :fields="fieldsUsers"
                  :current-page="currentPage"
                  :per-page="perPage"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  stacked="sm"
                  show-empty
                  small
                  @filtered="onFiltered"
                  @row-clicked="showModalUser"
              >
                <template #cell(log)="data">
                  <b-form-checkbox
                      v-model="data.item.log"
                      name="log"
                      value="1"
                      unchecked-value="0"
                      size="lg"
                      switch
                      @change="changeLogData(data.item.log, data.item.id)">
                  </b-form-checkbox>
                </template>


                <template #cell(history)="data">
                  <b-form-checkbox
                      v-model="data.item.log_history"
                      name="log_history"
                      value="1"
                      unchecked-value="0"
                      size="lg"
                      switch
                      checked="0"
                      @change="changeLogHistoryUser(data.item.log_history, data.item.id)">
                  </b-form-checkbox>
                </template>

              </b-table>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="3">
              <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  size="sm"
              ></b-pagination>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>
    <b-modal id="modal-user" hide-footer>
      <template #modal-title>
        <b-col md="12" v-if="isModifying">Modifier « {{ username }} »</b-col>
        <b-col md="12" v-else>Création d'un utilisateur</b-col>
        <b-col md="12" class="modal-subtitle">Relisez bien les informations renseignées avant de valider</b-col>
      </template>
      <div class="d-block">
        <b-row>
          <b-col md="12">
            <b-form-group valid-feedback="Parfait !" :state="stateUsername" :invalid-feedback="invalidUsername"
                          label="Nom d'utilisateur" label-for="username-input">
              <b-form-input id="username-input" type="text" v-model="username" :state="stateUsername"
                            trim></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group valid-feedback="Parfait !" :state="stateEmail" :invalid-feedback="invalidEmail"
                          label="Adresse email" label-for="email-input">
              <b-form-input id="email-input" type="email" v-model="email" :state="stateEmail" trim></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group label="Rang">
              <b-form-select v-model="rank" :options="options"></b-form-select>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group valid-feedback="C'est du solide 🤗" :state="statePassword" :invalid-feedback="invalidPassword">
              <b-icon @click="generatePassword()" icon="tools" v-b-tooltip.top="'Générer un mot de passe'" style="cursor: pointer;"/>&nbsp;<label for="password-input">Mot de passe</label>
              <b-form-input id="password-input" type="password" v-model="password" :state="statePassword"
                            trim></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-button @click="sendForm" variant="primary" :disabled="stateValidate" block>Valider</b-button>
          </b-col>
          <b-col v-if="isModifying">
            <b-button type="button" class="btn btn-danger" variant="primary" @click="deleteUser" block>Supprimer
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-modal>
    <b-modal v-if="userStatshop" size="xl" id="modal-user-statshop" hide-footer centered>
      <template #modal-title>
        <b-col md="12">Modifier « {{ userStatshop.firstName }} {{ userStatshop.lastName }} »</b-col>
        <b-col md="12" class="modal-subtitle" v-if="userStatshop.accountable">Cet utilisateur possède le privilège « Responsable », il a donc accès par défaut à tous les sites</b-col>
        <b-col md="12" class="modal-subtitle" v-else>Cet utilisateur ne possède pas le privilège « Responsable »</b-col>
      </template>
      <div class="d-block" style="height: 410px;">
        <b-row>
          <b-col md="12" class="mb-3">
            <label class="typo__label font-weight-bold">Définir une date d'expiration pour les accès :</label>
            <b-form-datepicker id="example-datepicker" v-model="validUntil" label-no-date-selected="Aucune date d'expiration" :min="minValidUntil" :max="maxValidUntil" reset-button></b-form-datepicker>
          </b-col>
          <b-col md="12">
            <label class="typo__label font-weight-bold">Définir les sites attribués à l'utilisateur :</label>
            <multiselect v-model="userStatshopAttributedSites" :options="userStatshopSites" :multiple="true"
                         :close-on-select="false" :clear-on-select="false" :preserve-search="true"
                         placeholder="Attribuer un site" label="login" track-by="id" :preselect-first="true"
                         :show-labels="true"
                         @select="attributeSite"
                         @remove="removeSite">
              <template slot="selection" slot-scope="{ values, search, isOpen }">
                <span class="multiselect__single" v-if="values.length && !isOpen">{{
                    values.length
                  }} sites attribués</span>
              </template>
              <template slot="tag">{{ '' }}</template>
            </multiselect>
            <b-row>
              <b-col md="4">
                <b-button @click="attributeAllSites" type="button" variant="primary" block>Sélectionner tous les sites
                </b-button>
              </b-col>
              <b-col md="4">
                <b-button @click="attributeAllHotels" type="button" variant="primary" block>Sélectionner tous les hôtels
                </b-button>
              </b-col>
            </b-row>
          </b-col>
          <b-col md="12" class="mt-3">
            <b-form-group
                label-class="font-weight-bold"
                label="Activer ou désactiver le mode « Employé » de l'utilisateur :"
                v-slot="{ ariaDescribedby }"
            >
              <b-form-checkbox
                  v-model="userStatshop.isEmployee"
                  name="log"
                  value="1"
                  unchecked-value="0"
                  size="lg"
                  switch
                  :aria-describedby="ariaDescribedby"
                  @change="toggleEmployee">
              </b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col md="12" class="mt-3" v-if="userStatshop.isEmployee > 0">
            <b-form-group
                label-class="font-weight-bold"
                label="Activer ou désactiver le mode « Responsable » de l'employé :"
                v-slot="{ ariaDescribedby }"
            >
              <b-form-checkbox
                  v-model="userStatshop.accountable"
                  name="log"
                  value="1"
                  unchecked-value="0"
                  size="lg"
                  switch
                  :aria-describedby="ariaDescribedby"
                  @change="toggleAccountable">
              </b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col md="12" class="mt-3" v-if="userStatshop.isEmployee > 0">
            <b-form-group
                label-class="font-weight-bold"
                label="Afficher ou cacher l'employé dans la liste d'assignation des sites :"
                v-slot="{ ariaDescribedby }"
            >
              <b-form-checkbox
                  v-model="userStatshop.isEmployeeDisplayed"
                  name="log"
                  value="1"
                  unchecked-value="0"
                  size="lg"
                  switch
                  :aria-describedby="ariaDescribedby"
                  @change="toggleEmployeeDisplay">
              </b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>

<script>
import generator from 'generate-password'
import dayjs from "dayjs";

export default {
  name: 'Home',
  components: {},
  data: () => ({
    name: "Admin",
    test: true,
    isLoading: false,
    options: [],
    showInfoAlert: true,
    validUntil: null,
    minValidUntil: null,
    maxValidUntil: null,
    fieldsUsers: [
      {key: 'id', label: '#', sortable: true},
      {key: 'username', label: 'Username', sortable: true},
      {key: 'email', label: 'Email', sortable: true},
      {key: 'rank', label: 'Rank', sortable: true},
      {key: 'log', label: 'Log'},
      {key: 'history', label: 'History'},
      {key: 'latest_ip', label: 'Latest IP'}
    ],
    fieldsUsersStatshop: [
      {key: 'id', label: '#', sortable: true},
      {key: 'lastName', label: 'Lastname', sortable: true},
      {key: 'firstName', label: 'Firstname', sortable: true},
      {key: 'email', label: 'Email', sortable: true}
    ],
    types: [],
    totalRows: 1,
    totalRowsStatshop: 1,
    currentPage: 1,
    currentPageStatshop: 1,
    perPage: 10,
    perPageStatshop: 10,
    filter: null,
    filterStatshop: null,
    filterOn: [],
    filterOnStatshop: [],
    users: [],
    usersStatshop: [],
    userStatshop: null,
    userStatshopSites: [],
    userStatshopSitesWithSubsites: [],
    userStatshopAttributedSites: [],
    filteredItems: [],
    requests: [],
    username: null,
    email: null,
    rank: null,
    isModifying: true,
    password: ""
  }),
  mounted() {
    this.minValidUntil = dayjs().add(1, 'day').toDate()
    this.maxValidUntil = dayjs().add(3, 'month').toDate()
    this.$http.get(`/users`).then((response) => {
      this.users = response ? response.data.content : []
      this.totalRows = this.users.length
    })
    this.$http.get(`/users/ranks`).then((response) => {
      this.options = response ? response.data.content : []
    })
    this.$http.get(`/users/statshop`).then((response) => {
      this.usersStatshop = response ? response.data.content : []
      this.totalRowsStatshop = this.usersStatshop.length
    })
  },
  computed: {
    stateValidate() {
      if (this.isModifying) return !(this.stateUsername && this.stateEmail && this.statePassword && this.rank)
      return !(this.stateUsername && this.stateEmail && this.rank)
    },
    stateUsername() {
      return this.username !== null && this.username.length >= 4
    },
    invalidUsername() {
      if (this.username == null || this.username.length > 0) {
        return 'Veuillez saisir au moins 4 charactères'
      } else {
        return 'Veuillez saisir un nom d\'utilisateur'
      }
    },
    stateEmail() {
      // THE AMAZING REGEX EMAIL VALIDATION FROM STACKOVERFLOW
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return this.email !== null && re.test(this.email.toLowerCase())
    },
    invalidEmail() {
      return 'Veuillez saisir une adresse email valide'
    },
    statePassword() {
      if (this.isModifying && this.password === "") return true
      // credit to www.thepolyglotdeveloper.com as I'm awful with regular expressions
      // at least 1 lowercase and 1 uppercase letter, 1 numeric and 1 special character and 8 characters long
      const re = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$?(/)\\%^&*}{;~,.=+_:])(?=.{8,})");
      return this.password !== null &&
          re.test(this.password) &&
          this.username !== 'test'
    },
    invalidPassword() {
      if (this.password == null || this.password.length > 0) {
        return 'Veuillez saisir au moins 8 caractères avec au moins un de chaque comprenant: [a-z A-Z 0-9 !@#$?(/)\\%^&*}{;~,.=+_]';
      } else {
        return 'Veuillez saisir un mot de passe'
      }
    }
  },
  methods: {
    async attributeSite(site) {
      const id_site = site.id
      const id_user = this.userStatshop.id
      const valid_until = this.validUntil && this.validUntil.length > 0 ? this.validUntil : null
      await this.$http.post(`/users/statshop`, {
        id_site,
        id_user,
        valid_until,
        add: true
      })
    },
    async attributeAllSites() {
      const sitesToAttribute = this.userStatshopSites.filter((site) => (this.userStatshopAttributedSites.find(attributedSite => attributedSite.id === site.id) === undefined) ?? null);
      for(let site of sitesToAttribute) {
        const id_site = site.id
        const id_user = this.userStatshop.id
        const valid_until = this.validUntil && this.validUntil.length > 0 ? this.validUntil : null
        await this.$http.post(`/users/statshop`, {
          id_site,
          id_user,
          valid_until,
          add: true
        })
      }
      //! todo refresh vue here to apply modifications to multiselect component
    },
    async attributeAllHotels() {
      const hotelsToAttribute = this.userStatshopSitesWithSubsites.filter((site) => (this.userStatshopAttributedSites.find(attributedSite => attributedSite.id === site.id) === undefined) ?? null);
      for(let site of hotelsToAttribute) {
        const id_site = site.id
        const id_user = this.userStatshop.id
        const valid_until = this.validUntil && this.validUntil.length > 0 ? this.validUntil : null
        await this.$http.post(`/users/statshop`, {
          id_site,
          id_user,
          valid_until,
          add: true
        })
      }
      //! todo refresh vue here to apply modifications to multiselect component
    },
    async toggleAccountable() {
      const id_user = this.userStatshop.id
      const accountable = this.userStatshop.accountable
      await this.$http.patch(`/users/statshop/accountable/${id_user}`, {
        accountable: accountable
      })
      await this.refreshUserSitesAttributions(this.userStatshop.id)
    },
    async toggleEmployee() {
      const id_user = this.userStatshop.id
      const employee = this.userStatshop.isEmployee
      await this.$http.patch(`/users/statshop/employee/${id_user}`, {
        employee: employee
      })
      this.userStatshop.isEmployeeDisplayed = 1;
      this.userStatshop.accountable = 0;
    },
    async toggleEmployeeDisplay() {
      const id_user = this.userStatshop.id
      const display = this.userStatshop.isEmployeeDisplayed
      await this.$http.patch(`/users/statshop/employee/${id_user}/display`, {
        display: display
      })
    },
    async removeSite(site) {
      const id_site = site.id
      const id_user = this.userStatshop.id
      await this.$http.post(`/users/statshop`, {
        id_site,
        id_user,
        add: false
      })
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    onFilteredStatshop(filteredItems) {
      this.totalRowsStatshop = filteredItems.length
      this.currentPageStatshop = 1
    },
    showModalUser(row) {
      this.$store.state.user = row
      this.username = row.username
      this.email = row.email
      this.rank = row.rank
      this.isModifying = true
      this.$bvModal.show('modal-user')
    },
    async showModalUserStatshop(row) {
      this.isLoading = true;
      this.userStatshop = row
      await this.refreshUserSitesAttributions(this.userStatshop.id)
      this.$bvModal.show('modal-user-statshop')
      this.isLoading = false;
    },
    async refreshUserSitesAttributions(id) {
      await this.getEnabledSites()
      await this.getEnabledSitesWithSubsites()
      await this.getAttributedSites(id)
    },
    async getAttributedSites(id) {
      const response = await this.$http.get(`/users/statshop/${id}/sites`)
      this.userStatshopAttributedSites = response ? response.data.content : []
    },
    async getEnabledSites() {
      const response = await this.$http.get(`/customers/enabled`)
      this.userStatshopSites = response ? response.data.content : []
    },
    async getEnabledSitesWithSubsites() {
      const response = await this.$http.get(`/customers/enabled-with-subsite`)
      this.userStatshopSitesWithSubsites = response ? response.data.content : []
    },
    showModalCreateUser() {
      this.$store.state.user = null
      this.username = ""
      this.email = ""
      this.password = ""
      this.rank = 1
      this.isModifying = false
      this.$bvModal.show('modal-user')
    },
    getUser() {
      return this.$store.state.user
    },
    sendForm: function () {
      if (this.isModifying) this.sendFormModify()
      else this.sendFormCreate()
    },
    sendFormModify: function () {
      if (this.stateUsername && this.stateEmail && this.rank) {
        let body = {
          username: this.username,
          email: this.email,
          rank: this.rank,
        }
        if (this.statePassword) body.password = this.password;
        this.$http.put(`/users/${this.getUser().id}`, body)
            .then((response) => {
              this.handleHttpResponse(response);
            })
            .catch(() => {
              this.$bvToast.toast("Bad Request, check form data", {
                title: "Error when updating user",
                variant: 'danger',
                solid: true
              })
            })
      }
    },
    sendFormCreate: function () {
      if (this.stateUsername && this.stateEmail && this.statePassword && this.rank) {
        this.$http.post(`/users`, {
          username: this.username,
          email: this.email,
          rank: this.rank,
          password: this.password
        })
            .then((response) => {
              this.handleHttpResponse(response);
            })
            .catch(() => {
              this.$bvToast.toast("Bad Request, check form data", {
                title: "Error when creating user",
                variant: 'danger',
                solid: true
              })
            })
      }
    },
    deleteUser: function () {
      this.$http.delete(`/users/${this.getUser().id}`)
          .then((response) => {
            this.handleHttpResponse(response);
          })
          .catch(() => {
            this.$bvToast.toast("Bad Request, check API logs", {
              title: "Error when deleting user",
              variant: 'danger',
              solid: true
            })
          })
    },
    handleHttpResponse: function (response) {
      if (!response) throw new Error();
      this.users = response.data.content;
      this.totalRows = this.users.length;
      this.$bvModal.hide('modal-user');
      this.$bvToast.toast("Opération correctement effectuée.", {
        title: "Succès",
        variant: 'success',
        solid: true
      })
    },
    changeLogData(log, id) {
      let body = {
        log: log === "1" ? 1 : 0,
      }
      if (this.statePassword) body.password = this.password;
      this.$http.put(`/users/log/` + id, body)
          .then((response) => {
            this.handleHttpResponse(response);
          })
          .catch(() => {
            this.$bvToast.toast("Bad Request, check form data", {
              title: "Error when updating user",
              variant: 'danger',
              solid: true
            })
          })
    },
    changeLogHistoryUser(log_history, id) {
      let body = {
        log_history: log_history === "1" ? 1 : 0,
      }
      if (this.statePassword) body.password = this.password;
      this.$http.put(`/users/log_history/` + id, body)
          .then((response) => {
            this.handleHttpResponse(response);
          })
          .catch(() => {
            this.$bvToast.toast("Bad Request, check form data", {
              title: "Error when updating user",
              variant: 'danger',
              solid: true,
              strict: true
            })
          })
    },
    generatePassword() {
      this.password = generator.generate({
        length: 32,
        numbers: true,
        symbols: true
      });
      this.$bvToast.toast("Mot de passe généré!", {
        title: "Succès",
        variant: 'success',
        solid: true,
        strict: true
      })
    }

  },
}
</script>

<style lang="scss" scoped>

</style>

<script setup>
import { ref } from "vue";
import { useApi } from '@/composables/useApi';
import { useToast } from '@/composables/useToast';

const api = useApi();
const bvToast = useToast();
let accepted = ref(false);
let metaUrl = ref('');
let metaTrackingVariable = ref(null);
let fieldsMetaTrackingVariable = [
  {key: 'login', label: 'Login', sortable: false},
  {key: 'id', label: 'Id du levier', sortable: false},
  {key: 'libelle', label: 'Libellé', sortable: false},
  {key: 'level', label: 'Niveau du levier', sortable: false},
];

async function getMetaTrackingVariable(event) {
  event.preventDefault()
  try {
    const metaUrlRegex = /https:\/\/facebook-wrapper.360and1.com\/([0-9])+\/act_([0-9])+\/campaigns/
    if (!metaUrlRegex.test(metaUrl.value)) {
      bvToast.toast("L'url ne correspond pas à une campagne provenant du wrapper facebook", {
        title: null,
        noCloseButton: true,
        variant: 'danger',
        solid: true
      });
      return
    }
    const account = metaUrl.value.replace('https://facebook-wrapper.360and1.com/', '').split('/')[1]
    const [result] = await Promise.all([
      api.get(`/desync/meta-url/${account}`),
    ]);
    metaTrackingVariable.value = result?.data?.content;
  } catch (error) {
    handleError(error);
  }
}

function handleError(error) {
  if (error.response) {
    bvToast.toast(error.response.data.content, {
      title: null,
      noCloseButton: true,
      variant: 'danger',
      solid: true
    });
  }
}
</script>

<template>
  <div class="wapp-desync">
    <b-row align-v="center">
      <b-col md="8" offset-md="2">
        <h3>Désynchroniser un levier Meta</h3>
        <hr class="my-4">
        <b-alert variant="warning" show>
            <b-form-checkbox v-model="accepted">Cet outil est destiné à éviter la surcharge de logs dans le channel Slack #log-script, en permettant de trouver le bon levier à désynchroniser.<br/><strong>A ne surtout pas utiliser avec une url qui ne provient pas du channel #log-script car cela va délier le levier</strong></b-form-checkbox>
        </b-alert>
        <div v-if="accepted">
          <b-form @submit="getMetaTrackingVariable">
            <b-form-input
              class="mb-3"
              v-model="metaUrl"
              type="url"
              placeholder="https://facebook-wrapper.360and1.com/"
            ></b-form-input>
            <b-button type="submit" variant="primary" block>Trouver le levier</b-button>
          </b-form>
          <div v-if="metaTrackingVariable" class="mt-5">
            <b-table
                striped hover
                empty-text="Aucun levier n'a été trouvé"
                :items="metaTrackingVariable"
                :fields="fieldsMetaTrackingVariable"
                :current-page="1"
                :per-page="10"
                stacked="sm"
                show-empty
                small
            >
            </b-table>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
